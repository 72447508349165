import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Meta from '../components/meta'
import Layout from '../components/layout'
import styles from './tags.module.css'
import Breadcrumb from '../components/breadcrumb'

export default ({data, pageContext, location}) => {
  const site = data.site
  console.log(data)
  const tags = get(data, 'tags.edges')
  const breadcrumbList = [{str: 'トップ', path: '/'}, {str: 'タグ'}]

  return (
    <Layout location={location}>
      <div className={styles.tagWrapper}>
        <Meta
          site={site}
        />
        <div className="wrapper">
          <Breadcrumb breadcrumbList={breadcrumbList}/>
          <div className={styles.tagContaier}>
            <h2 className="section-headline">タグ一覧</h2>
            <ul className={styles.tagList}>
              {tags.map(({ node }) => {
                return (
                  <li className={styles.tag} key={node.name}>
                    <Link to={`/tags/${node.slug}`} className={styles.tagText}>{node.name}</Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TagQuery {
    site {
      siteMetadata {
        title
        tagline
        author
        host
        description
      }
    }
    tags: allContentfulTag(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
